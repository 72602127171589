/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import AccountLayout from '../account/AccountLayout';
import Layout from '../components/layout';
import Container from '../components/Container';
import Link from '../components/Link';
import { Button } from '../components';
import FullHeightColumn from '../components/FullHeightColumn';
import { RecipientFormik } from '../parcelFlow/Recipient';
import { PickupForm } from '../parcelFlow/PickupPoint';
import { showNotification } from '../state/notifications';
import { locNavigate } from '../state/session';
import { reloadShipments } from '../state/shipments';
import { isBrowser } from '../utils';
import * as api from '../utils/api';
import { downloadFile } from '../utils/fileDownload';
import * as analytics from '../utils/analytics';

const STEP = {
  PHONE_AND_ADDRESS: 1,
  PICKUP_POINT: 2,
  DONE: 3,
};

const RecipientStep = ({ shipmentNumber, recipient, requireAddress, goNext }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const onBackClick = useCallback(() => window.history.back(), []);

  const formProps = {
    recipient,
    country: 'FI',
    requireAddress,
    onSubmit: goNext,
    translate,
  };

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('updateShipment.title')}</Styled.h1>
      <div sx={{ mb: 4 }}>
        {translate('updateShipment.shipmentNumber')} {shipmentNumber}
      </div>

      <Flex
        sx={{
          flex: ['auto', null, 'none'],
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <RecipientFormik {...formProps} />
      </Flex>
    </FullHeightColumn>
  );
};

const PickupPointStep = ({
  shipmentNumber,
  recipient,
  product,
  pickupPoint: initialPickupPoint,
  officeCode,
  officeName,
  goNext,
  goBack,
  setOfficeCode,
}) => {
  const [pickupPoint, setPickupPoint] = useState(initialPickupPoint);
  const onSelect = useCallback(
    pp => {
      setPickupPoint(pp);
      setOfficeCode(pp.officeCode);
    },
    [setPickupPoint, setOfficeCode]
  );

  const country = 'FI';
  const props = {
    pickupPoint,
    product,
    country,
    recipient,
    onBackClick: goBack,
    onNextClick: goNext,
    onSelect,
  };

  return (
    <FullHeightColumn>
      <PickupForm {...props} />
    </FullHeightColumn>
  );
};

const ReadyStep = ({ shipmentNumber, addressCard }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const toOwnParcels = useCallback(() => {
    dispatch(locNavigate('/my-pages/sent-parcels'));
  }, [dispatch]);
  const onClick = useCallback(() => {
    downloadFile(addressCard);
  }, [addressCard]);

  return (
    <FullHeightColumn>
      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('updateShipment.ready')}</Styled.h1>
      {addressCard && (
        <>
          <p sx={{ my: 2 }}>
            {translate('updateShipment.loadAddressCard')}{' '}
            <Button variant="plain" onClick={onClick}>
              {translate('updateShipment.here')}
            </Button>{' '}
            {translate('updateShipment.loadAddressCard2')}
          </p>
          <p>{translate('updateShipment.loadAddressCard3')}</p>
        </>
      )}
      <p>
        <Link to="/my-pages/sent-parcels">{translate('delivery.confirmation.return')}</Link>
      </p>
    </FullHeightColumn>
  );
};

export default ({ location: { state } = {}, pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();

  const onBackClick = useCallback(() => window.history.back(), []);

  const stateKeys = Object.keys(state || {});
  const hasState = stateKeys.includes('shipment') || stateKeys.includes('orderDetails');

  if (!hasState) {
    try {
      state = isBrowser && JSON.parse(window.sessionStorage.getItem('updateRecipient'));
    } catch (err) {
      // no-op
    }
  } else if (!state.saved) {
    // remember shipment if user refresh browser
    isBrowser && window.sessionStorage.setItem('updateRecipient', JSON.stringify(state));
    state.saved = true;
  }

  const [step, setStep] = useState(STEP.PHONE_AND_ADDRESS);
  const { shipment, orderDetails, pickupPoint } = state;
  const { shipmentNumber, shipmentStatus, receiverName, destinationPlaceId, destinationPlaceName } = shipment || {}; // from mpaketti
  const shipmentInOrder = ((orderDetails || {}).shipments || []).find(s => s.shipmentNumber === shipmentNumber); // dynamoDB
  const { recipient: initialRecipient, product } = shipmentInOrder || {};
  const [recipient, setRecipient] = useState({
    ...(initialRecipient || {}),
    name: receiverName,
  });
  const hasAddress = recipient.postcode && recipient.city;
  const isExpress = !!shipment?.connection;
  const requireAddress = +shipmentStatus === 2 && hasAddress;
  const askPickupPoint = +shipmentStatus === 2 && !hasAddress && !isExpress;
  const [addressCard, setAddressCard] = useState();

  const updateRecipient = useCallback(
    async values => {
      try {
        const response = await api.updateRecipient(shipmentNumber, values);
        await dispatch(reloadShipments());
        const { addressCard } = response;
        if (addressCard) {
          setAddressCard(addressCard);
        }
        setStep(STEP.DONE);
      } catch (error) {
        dispatch(showNotification('genericApiError'));
      }
    },
    [dispatch, shipmentNumber, setAddressCard, setStep]
  );

  const goBack = useCallback(() => {
    setStep(s => s - 1);
  }, [setStep]);

  const submitRecipient = useCallback(
    values => {
      setRecipient(values);
      if (step === STEP.PHONE_AND_ADDRESS && askPickupPoint) {
        setStep(STEP.PICKUP_POINT);
      } else {
        updateRecipient(values);
      }
    },
    [askPickupPoint, step, setStep, updateRecipient]
  );

  const selectPickupPoint = useCallback(
    officeCode => {
      setRecipient(oldVal => ({ ...oldVal, pickupPoint: officeCode }));
    },
    [setRecipient]
  );

  const confirmPickupPoint = useCallback(async () => {
    await updateRecipient(recipient);
  }, [updateRecipient, recipient]);

  return (
    <AccountLayout
      title={translate('updateShipment.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      checkShopDisturbance={true}
      /*
      sidebar={
        step === STEP.PAYMENT ? null : (
          <Box>
            <SendParcelWidget sidebar sx={{ mb: 3 }} />
            <TrackingSearchWidget sidebar />
          </Box>
        )
      }
      */
    >
      <Container>
        {step === STEP.PHONE_AND_ADDRESS ? (
          <RecipientStep
            goNext={submitRecipient}
            requireAddress={requireAddress}
            recipient={recipient}
            shipmentNumber={shipmentNumber}
          />
        ) : step === STEP.PICKUP_POINT ? (
          <PickupPointStep
            setOfficeCode={selectPickupPoint}
            goNext={confirmPickupPoint}
            goBack={goBack}
            shipmentNumber={shipmentNumber}
            recipient={recipient}
            product={product}
            pickupPoint={
              pickupPoint || {
                officeCode: destinationPlaceId,
                officeName: destinationPlaceName,
              }
            }
          />
        ) : step === STEP.DONE ? (
          <ReadyStep shipmentNumber={shipmentNumber} addressCard={addressCard} />
        ) : null}
      </Container>
    </AccountLayout>
  );
};
